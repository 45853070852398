import type { CollectionNames, StoreRecordData } from "~/services/store";
import { recordFactory } from "~/services/store";

export default function <T>(collectionName: CollectionNames, defaults: any): StoreRecordData<T> {
	const id = "new";

	const input = { id, ...defaults };

	const record = recordFactory(input, collectionName) as StoreRecordData<T>;

	record.internals.saved = false;

	return record;
}
